.str-chat__channel-search-bar-button [fill^='#'] {
  fill: currentColor;
}

.str-chat__container {
  flex-direction: column;
}

/* [AS-669] Add spacing between paragraphs and text blocks */
.str-chat__message-text-inner > div {
  display: flex;
  flex-direction: column;
}
.str-chat__message-text-inner > div :is(ul, ol) {
  padding-inline-start: 1rem;
}
.str-chat__message-text-inner > div blockquote {
  border-inline-start: 2px solid currentColor;
  padding-inline-start: 0.5rem;
}

.image-gallery-slide {
  position: relative; /* Fix: AS-897 */
}

/* The following re-implements https://github.com/ArenaLabs/strive_react/pull/683 with some additional tweaks */
.image-gallery-slides {
  display: grid;
  place-items: center;
}
.image-gallery-slides > * {
  grid-area: 1/1;
}

.str-chat__modal--open {
  padding-top: calc(env(safe-area-inset-top) + 80px);
  max-height: calc(100% - env(safe-area-inset-bottom) - 145px);
}

.str-chat__modal--open button.str-chat__modal__close-button {
  z-index: 1;
}

.image-gallery-image {
  margin-top: 70px;
}

.image-gallery-index {
  margin-top: calc(env(safe-area-inset-top) + 80px);
  border-radius: 5px;
  background: gray;
  opacity: 80%;
}

.str-chat__message-attachment--card .str-chat__modal--open .str-chat__modal__inner.str-chat-react__modal__inner img, .str-chat__message-attachment--image .str-chat__modal--open .str-chat__modal__inner.str-chat-react__modal__inner img, .str-chat__message-attachment--gallery .str-chat__modal--open .str-chat__modal__inner.str-chat-react__modal__inner img,
.str-chat__message-attachment--image .str-chat__modal--open .str-chat__modal__inner.str-chat-react__modal__inner img {
  max-height: calc(100vh - env(safe-area-inset-bottom) - 145px);
}